<div class="disease-multidisciplinary-wrapper container-fluid py-5">
    <div class="container-xxxl py-lg-4">
        <!-- Desktop -->
        <div class="desktop d-none d-xl-flex justify-content-between">
            <div class="margin-text">
                <app-bar-header [textHeader]="textHeader" [theme]="theme"></app-bar-header>

                <div class="mt-2">
                    <img [src]="imgPath + bodyKA.images[0]?.url + '-600.webp'" [alt]="bodyKA.images[0]?.altText" />
                </div>
            </div>
            <div class="ms-5 mt-2">
                <header-h3 class="mb-4" [theme]="theme" fontWeight="700" [textHeader]="bodyKA.title"></header-h3>
                <p class="description fs-18 mt-5 mb-4" [innerHTML]="bodyKA.bodyText"></p>
                <p *ngIf="pediatricsKAShouldShow" class="fs-6 mb-0" [innerHTML]="pediatricsKA.bodyText"></p>
            </div>
        </div>

        <!-- Mobile -->
        <div class="mobile d-flex d-xl-none flex-column">
            <app-bar-header [textHeader]="textHeader" [theme]="theme"></app-bar-header>

            <div>
                <header-h3 class="mb-4" [theme]="theme" fontWeight="700" [textHeader]="bodyKA.title"></header-h3>
            </div>

            <div class="mt-3 mb-4 mx-auto">
                <img [src]="imgPath + bodyKA.images[0]?.url + '-600.webp'" [alt]="bodyKA.images[0]?.altText" />
            </div>

            <div>
                <div class="mt-2">
                    <p class="fs-6 mb-0" [innerHTML]="bodyKA.bodyText"></p>
                </div>

                <div *ngIf="pediatricsKAShouldShow" class="mt-2">
                    <p class="fs-6 mb-0" [innerHTML]="pediatricsKA.bodyText"></p>
                </div>
            </div>
        </div>
    </div>
</div>
